.album-select-modal {
  position: relative;
  width: min(600px, 100%);
}
.album-select-modal .dialog-buttons {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #e6e6e6;
  padding: 1em;
  margin-bottom: 0;
}

.album-select-modal .album-select-item-box {
  background-color: rgba(255, 255, 255, 0.1);
}
.album-select-modal .album-select-item-box:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.album-select-modal .album-select-fields {
  display: flex;
  align-items: center;
  padding-right: 48px;
}
.album-select-modal .album-select-fields > * {
  display: flex;
  align-items: center;
  align-content: center;
}
.album-select-modal .album-select-indicator {
  min-width: 2em;
  line-height: 1;
}
.album-select-modal .album-select-title {
  width: 100%;
  min-width: min(15em, 25%);
}
.album-select-modal .album-select-modelname {
  width: 100%;
  min-width: min(15em, 25%);
}
.album-select-modal .album-select-photos {
  min-width: min(10em, 25%);
  padding: 0 0.5em;
  white-space: nowrap;
  margin: 0 -0.2em;
}
.album-select-modal .album-select-photos div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.2em;
}

@media screen and (max-width: 600px) {
  .album-select-modal {
    width: 100%;
  }
  .album-select-modal .album-select-modelname {
    display: none;
  }
  .album-select-modal .album-select-photos div:first-child {
    display: none;
  }
  .album-select-modal .album-select-btn {
    padding: 0.25em;
  }
}
