* {
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input, textarea, select {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}

html {
  font-size: 14px;

  --red: #ff4136;
  --red-rgb: 255, 65, 54;

  --green: #2ecc40;
  --green-rgb: 46, 204, 64;

  /* --blue: #3f51b5;
  --blue-rgb: 63, 81, 181; */
  /* --blue: #7dbee8;
  --blue-rgb: 125, 190, 232; */
  --blue: #6295b6;
  --blue-rgb: 98, 149, 182;

  --orange: #d84315;
  --orange-rgb: 216, 67, 21;

  --pink: #f06292;
  --pink-rgb: 240, 98, 146;

  --purple: #7e5bad;
  --purple-rgb: 126, 91, 173;

  --yellow: #a0a000;
  --yellow-rgb: 160, 160, 0;

  --cyan: #008051;
  --cyan-rgb: 0, 128, 81;

}

body {
  overflow-x: hidden;
  margin: 0;
}

body .MuiDialog-paper {
  background-color: #e6e6e6;
}

/* @media screen and (max-width: 1250px) {
  html {
    font-size: 12px;
  }
}
@media screen and (max-width: 1000px) {
  html {
    font-size: 10px;
  }
} */


/* :is(button, div).loading-btn
:is(button[disabled], div.is-disabled).loading-btn */

:is(button, div, ul).loading-btn {
  transition: color 0.4s;
}
:is(button, div, ul).loading-btn > * {
  opacity: 1;
  transition: opacity 0.4s;
}
:is(button, div, ul).loading-btn:after {
  box-sizing: border-box;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.5em;
  width: 1em;
  height: 1em;
  border: 0.25em solid currentColor;
  border-radius: 50%;
  border-left-color: transparent;
  transform-origin: 50% 50%;
  animation: spin 1s linear infinite;
  opacity: 0;
  transition: opacity 0.4s;
  pointer-events: none;
}
:is(ul).loading-btn:after {
  font-size: 2em;
}
:is(button[disabled], div.is-disabled).loading-btn > * {
  opacity: 0;
}
:is(ul.is-disabled).loading-btn > * {
  opacity: 0.25;
}
:is(button[disabled], div.is-disabled, ul.is-disabled).loading-btn:after {
  opacity: 1;
}

@-moz-keyframes spin {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}


div.avatar {
  width: 1.2em;
  height: 1.2em;
  background-color: transparent;
}
