.random-btn {
  flex-direction: column;
}


.separator {
  display: inline-block;
  height: 1.3em;
  width: 0px;
  border-right: 1px solid #80808080;
  margin: 12px;
  margin-left: 0px;
  vertical-align: middle;
}

.token-list .reorder-list .reorder-li {
  flex-direction: column;
  align-items: stretch;
}
.token-list .reorder-list .reorder-li .reorder-icons {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
