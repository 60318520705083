.album-form .MuiGrid-container {
  padding: 0.5em 0 0.75em;
}
.album-form .MuiGrid-container:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.03);
}

.clearcover {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  /* background-color: rgba(255, 0, 0, 0.1); */
}

.thumb-box {
  position: relative;
}

.drag-box {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 200px;
  height: 200px;
  border: 2px dashed gray;
  backdrop-filter: saturate(400%);
}
.drag-box:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 33.33%;
  transform: translate(0, -50%);
  border-top: 2px solid gray;
  border-bottom: 2px solid gray;
  opacity: 0.25;
  touch-action: none;
  pointer-events: none;
}
.drag-box:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 33.33%;
  transform: translate(-50%, 0);
  border-left: 2px solid gray;
  border-right: 2px solid gray;
  opacity: 0.25;
  touch-action: none;
  pointer-events: none;
}

.handle {
  position: absolute;
  width: 20px;
  height: 20px;
  margin: -10px;
  background-color: white;
  outline: 2px solid rgba(0, 0, 0, 0.25);
  border-radius: 3px;
}

.handle-tl {
  top: 0;
  left: 0;
  cursor: nwse-resize;
}
.handle-tr {
  top: 0;
  right: 0;
  cursor: nesw-resize;
}
.handle-bl {
  bottom: 0;
  left: 0;
  cursor: nesw-resize;
}
.handle-br {
  bottom: 0;
  right: 0;
  cursor: nwse-resize;
}
.handle-mv {
  top: 50%;
  left: 50%;
  cursor: move;
}

/* .drag-box:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  margin: -5px;
  background-color: red;
}

.drag-box:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  margin: -5px;
  background-color: red;
} */

.thumb-img {
  width: 100%;
  filter: saturate(25%);
}

.hidden-fields {
  display: none;
}

.thumb-box-btn-row {
  display: flex;
  justify-content: space-evenly;
}

.thumb-box-buttons .thumb-box-size {
  display: flex;
  flex-flow: row nowrap;
  min-width: 8em;
  text-align: center;
  border-radius: 4px;
  transition: background-color 0.4s;
  background-color: #2ecc4011;
}
.thumb-box-buttons .thumb-box-size .thumb-box-size-info {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  flex: 1 1 0px;
  align-items: stretch;
}
.thumb-box-buttons .thumb-box-size.thumb-box-warning {
  background-color: #ff413611;
}
.thumb-box-buttons .thumb-box-size .thumb-box-size-info * {
  transition: color 0.4s;
}
.thumb-box-buttons .thumb-box-size .thumb-box-size-info *:first-child {
  color: var(--green);
  font-size: 0.85em;
}
.thumb-box-buttons .thumb-box-size .thumb-box-size-info *:last-child {
  font-size: 0.65em;
}

.thumb-box-buttons .thumb-box-size .thumb-box-size-info .thumb-box-warning {
  color: var(--red);
}
