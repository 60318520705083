.photo-select-openbtn {
  min-width: 10em;
  white-space: nowrap;
}

.photo-select-modal {
  position: relative;
  width: 600px;
}

.photo-select-modal .photo-select-form {
  margin-bottom: 0.5em;
  padding: 0 0.5em;
}
.photo-select-modal .photo-select-autocomplete {
  width: min(600px, 100%);
}
.photo-select-modal .photo-select-grid-container {
  padding: 0 0.5em;
  min-height: 100%;
  max-height: 600px;
  overflow: scroll;
}
.photo-select-modal .photo-select-grid {
  width: min(600px, 100%);
}

.photo-select-modal .photo-select-grid-item {
  position: relative;
  cursor: pointer;
  height: unset;
  aspect-ratio: 1/1;
}
.photo-select-modal .photo-select-grid-item .photo-select-grid-item-selected {
  position: absolute;
  inset: 0;
  background: var(--green);
  color: black;
  opacity: 0.3;

  display: flex;
  place-content: center;
  place-items: center;

  visibility: hidden;
}
.photo-select-modal .photo-select-grid-item.photo-select-selected .photo-select-grid-item-selected {
  visibility: visible;
}
.photo-select-modal .photo-select-grid-item .photo-select-grid-item-selected svg {
  font-size: 100px;
}

.photo-select-modal .photo-select-dialog-buttons {
  display: flex;
  justify-content: space-around;
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #e6e6e6;
  padding: 1em;
  margin-bottom: 0;
}
.photo-select-modal .photo-select-dialog-buttons > button {
  flex: 1 1 0px;
  max-width: 7em;
}
