.root-component {
  display: flex;
}

.root-component .root-drawer {
  flex-shrink: 0;
}
.root-component .root-drawer,
.root-component .root-drawer .root-drawer-paper {
  width: 20em;
  transition: width 0.25s ease-in-out;
  overflow-x: hidden;
}
.root-component .root-drawer.root-drawer-open,
.root-component .root-drawer.root-drawer-open .root-drawer-paper {
  width: 4em;
}

.root-component .drawer-btn {
  transform: rotate(0deg);
  transition: transform 0.75s ease-in-out;
}
.root-component .drawer-btn.drawer-btn-open {
  transform: rotate(180deg);
}

.root-drawer .root-header {
  display: flex;
  justify-content: flex-start;
}
.root-drawer .root-header .root-container {
  flex: 1 1 0px;
  display: flex;
  padding: 0 0.5em;
  justify-content: center;
}
.root-drawer .root-logo {
  width: 3em;
  height: 3em;
  margin: 1em 1em 1em 0em;
  filter: drop-shadow(0.1em 0.1em 0.1em rgba(0, 0, 0, 0.25));
}
.root-drawer .root-header .root-container p {
  font-size: 1.3rem;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root-component .root-main {
  flex-grow: 1;
  margin: 0 -0.3em;
  padding: 1em;
}

.root-component .root-main-list {
  flex-grow: 1;
}

.root-component .root-drawer .root-drawer-bottom {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: white;
}
.root-component .root-drawer .root-drawer-bottom .errored,
.root-component .root-drawer .root-drawer-bottom .errored .MuiListItemIcon-root {
  color: #e57373;
}

.nested {
  padding-left: 1em;
}

.MuiListItem-button.activeListItem {
  background-color: rgba(0, 0, 0, 0.1);
}

.link-item-text {
  white-space: nowrap;
}

@media screen and (max-width: 600px) {
  .root-component .drawer-btn {
    visibility: hidden;
  }
  .root-component .drawer-btn > * {
    display: none;
  }
  .root-component .root-main {
    max-width: calc(100vw - 3em);
  }
}
