.copy-link-button {
  min-width: 10em;
  white-space: nowrap;
}

/* .copy-link-iconbtn {} */

.copy-link-modal {
  position: relative;
  width: 600px;
}

.copy-link-modal .copy-link-dialog-buttons {
  display: flex;
  justify-content: space-around;
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: #e6e6e6;
  padding: 1em;
  margin-bottom: 0;
}
.copy-link-modal .copy-link-dialog-buttons > button {
  flex: 1 1 0px;
  max-width: 7em;
}
