.with-button {
  display: flex;
  place-items: flex-end;
}

.no-min-width {
  min-width: none;
}

.album-form div.album-top-btns-container {
  --select-rgb: var(--blue-rgb);
  padding: 12px 0;
}
.album-form .album-top-btns {
  display: flex;
  width: 100%;
}
.album-form .album-top-btns .album-top-btn {
  /* width: auto; */
  flex: 1 1 0px;
}

.album-form .album-top-btns .album-top-btn > * {
  display: flex;
  place-content: center;
  place-items: center;
  /* width: 100%; */
  margin: 0;
  padding: 0.5em;
  height: 100%;
}
.album-form .album-top-btns .album-top-btn > * button {
  pointer-events: none;
  margin-left: -12px;
}
.album-form .album-top-btns .album-top-btn.album-top-link > * {
  color: black;
  text-decoration: none;
  background-color: rgba(var(--select-rgb), 0);
}
.album-form .album-top-btns .album-top-link.album-top-btn.album-top-link:hover > * {
  background-color: rgba(var(--select-rgb), 0.1);
}

.album-form .album-top-btns .album-top-btn.album-top-link.album-top-link-arrow {
  flex: 0 0 3em;
}
.album-form .album-top-btns .album-top-btn.album-top-link:is(.album-top-link-arrow, .album-top-link-current):hover > * {
  background: none;
}
.album-form .album-top-btns .album-top-btn.album-top-link.album-top-link-current div {
  opacity: 0.5
}

.album-form .album-top-link-red {
  --select-rgb: var(--red-rgb);
}
.album-form .album-top-link-green {
  --select-rgb: var(--green-rgb);
}
.album-form .album-top-link-orange {
  --select-rgb: var(--orange-rgb);
}
.album-form .album-top-link-purple {
  --select-rgb: var(--purple-rgb);
}
.album-form .album-top-link-yellow {
  --select-rgb: var(--yellow-rgb);
}
.album-form .album-top-link-cyan {
  --select-rgb: var(--cyan-rgb);
}
.album-form .album-top-link-blue {
  --select-rgb: var(--blue-rgb);
}


.album-form .album-top-btns .album-top-btn.album-top-link .album-top-link-icon {
  margin: 0 0.8em;
}
.album-form .album-top-btns .album-top-btn.album-top-link .album-top-link-text {
  display: flex;
  place-content: center;
  place-items: center;
  flex-flow: column nowrap;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

}
.album-form .album-top-btns .album-top-btn.album-top-link .album-top-link-text div {
  opacity: 0.6;
}

/* .album-form .album-top-btns .album-top-btn.album-top-link .album-top-link-text div:nth-child(1) {
  font-size: 1.25em;
} */
/* .album-form .album-top-btns .album-top-btn.album-top-link .album-top-link-text div:nth-child(2) {
  opacity: 0.6;
} */
.album-form .album-top-btns .album-top-btn.album-top-btn-enabled {
  background-color: rgba(var(--select-rgb), 0.1);
}
.album-form .album-top-btns .album-top-btn.album-top-btn-enabled * {
  color: rgba(var(--select-rgb), 1);
}


@media screen and (max-width: 1000px) {
  .album-form .album-top-btns {
    flex-wrap: wrap;
  }
  .album-form .album-top-btns .album-top-btn {
    width: auto;
    flex: 1 1 0px;
  }
  .album-form .album-top-btns .album-top-btn:is(.album-top-link-prev, .album-top-link-next) {
    flex: 1 1 50%;
    width: 50%;
    order: -5;
  }
}
