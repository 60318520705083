.photos-title.MuiTypography-root {
  margin-top: 1em;
}
.photos-section {
  display: flex;
}
.photos-section .photos-list {
  flex: 1 1 50%;
  padding-right: 0.3em;
}
.photos-section .photos-list :is(button, svg, label, label span) {
  font-size: 1em;
}

.photos-section .photos-list .MuiGridListTile-root {
  height: unset !important;
  aspect-ratio: 1 / 1;
}

.photos-section .photos-list .MuiGridListTile-imgFullWidth {
  top: unset;
  width: unset;
  position: relative;
  transform: translateY(0%);
}

.photos-section .photos-thumb {
  flex: 1 1 50%;
  max-width: 500px;
}
.photos-section .grid-icon-btn {
  padding: 12px;
}
.photos-section .grid-icon-btn:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
.photos-section .list-icon-btn {
  padding: 12px;
}
.photos-section .MuiGridListTile-root .MuiIconButton-root:not(.MuiIconButton-colorSecondary, .MuiIconButton-colorPrimary, .Mui-disabled) {
  color: white;
}

.photos-section .MuiGridListTileBar-actionIcon {
  white-space: nowrap;
}
.photos-section .bottom-bar {
  justify-content: flex-end;
}

.photos-section .grid-photo-size {
  display: flex;
  flex-flow: column nowrap;
  place-items: center;
  place-content: center;
  font-size: 0.75em;
  line-height: 1;
  margin: 0 0 0 auto;
  max-width: fit-content;
}
.photos-section .grid-photo-size * {
  flex: 1 1 0px;
}
.photos-section .grid-photo-size span {
  flex: 0 1 0px;
  font-size: 0.5em;
}

.photos-section .grid-icon-btn.Mui-disabled {
  color: rgba(255, 255, 255, 0.26);
}
.photos-section .MuiGridListTileBar-titlePositionTop .grid-icon-btn:not(:first-child) {
  margin-left: -0.6em;
}
.photos-section .is-disabled img {
  filter: saturate(0);
}
.photos-section .MuiGridListTile-tile .MuiGridListTileBar-root {
  opacity: 0.1;
  transition: opacity 0.1s;
}
.photos-section .MuiGridListTile-tile:hover .MuiGridListTileBar-root {
  opacity: 0.9;
}
.photos-section .is-selected .MuiGridListTileBar-root {
  background: rgb(245 0 87 / 15%);
}



.photos-section .list-item:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.025);
}


.photos-section div.chip-xs {
  pointer-events: none;
  vertical-align: middle;
  height: 1.2em;
  margin: 0 0.2em 0.1em;
}
.photos-section div.chip-xs span {
  font-size: 0.8em;
}
.photos-section div.chip-red {
  color: var(--red);
  background-color: rgba(var(--red-rgb), 0.1);
}
.photos-section div.chip-green {
  color: var(--green);
  background-color: rgba(var(--green-rgb), 0.1);
}

.photos-section div.avatar {
  font-size: 0.8em;
  width: 1.2em;
  height: 1.2em;
}



@media screen and (max-width: 600px) {
  .photos-section {
    flex-direction: column;
  }
  .photos-section .grid-icon-btn {
    font-size: 0.75em;
  }
  .photos-section .grid-photo-size {
    font-size: 0.5em;
  }
}
@media screen and (max-width: 800px) {
  .photos-section .hide-sm {
    display: none;
  }
}
