.album-grid-controls-right {
  text-align: right;
}

.album-grid .MuiGridListTile-root {
  aspect-ratio: 1 / 1;
}
.album-grid .MuiGridListTile-root .MuiGridListTileBar-title a,
.album-grid .MuiGridListTile-root .MuiGridListTileBar-title a:active,
.album-grid .MuiGridListTile-root .MuiGridListTileBar-title a:visited {
  color: white;
}

.album-grid .album-grid-img {
  width: 100%;
  height: 100%;
}
.album-grid .album-grid-img.blurred {
  filter: blur(5px);
}
.album-grid .album-grid-disabled {
  opacity: 0.75;
  filter: brightness(0.75);
}

.album-grid .album-grid-bottom-bar {
  width: 100%;
}

.album-grid .grid-icon-btn {
  color: white;
  font-size: 1.5em;
  padding: 0.25em 0.5em;
}

.album-grid .grid-icon-btn svg {
  font-size: 1.5em;
}

.album-grid .grid-icon-btn:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
.album-grid .grid-icon-btn.Mui-disabled {
  color: rgba(255, 255, 255, 0.26);
}

.album-grid .album-list-photo-count {
  display: inline;
  color: white;
  vertical-align: middle;
  font-size: 1em;
  opacity: 0.6;
}
.album-grid .album-list-photo-count > div {
  display: inline-flex;
  align-items: center;
  margin: 0 0.2em;
}
.album-grid .album-list-photo-count > div > svg {
  font-size: 1em;
  margin-right: 0.1em;
}
