.item-box > * {
  margin: 0 0.25em;
  flex: 1 1 100%;
}

.reorder-list li {
  margin: 0.25em;
  border-radius: 1em;
  max-width: -webkit-fill-available;
}
.reorder-list li:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
.reorder-list li:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

.reorder-list .list-item-disabled {
  opacity: 0.5;
}

@media screen and (max-width: 1000px) {
  .reorder-list li {
    flex-wrap: wrap;
    justify-content: center;
    max-width: calc(100vw - 6em);
  }

  .reorder-list .item-box {
    flex-wrap: wrap;
    justify-content: center;
  }
  .reorder-list .item-box > * {
    margin: 0.5em;
  }
}
