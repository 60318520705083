.form-footer {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  align-items: flex-start;
}
.form-sticky-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100% + 2em);
  z-index: 1;
  margin: 0 -1em -1em;
  padding: 0 1em;
  background-color: rgba(255, 255, 255, 0.8);
  min-height: 4em;
  align-items: center;
}
.form-buttons {
  margin: 0.5em;
  flex: 0 0 0px;
}
.form-feedback {
  flex: 1 1 0px;
}
