.reference-image {
  max-width: 100%;
  width: 7em;
  border-radius: 50%;
  outline: 2px solid #00000022;
}

.reference-list-photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 0;
}

@media screen and (max-width: 1000px) {
  .reference-list-photo {
    order: -2;
  }
}
